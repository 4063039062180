import React from "react";

import PageWrapper from "../components/PageWrapper";
import ContentThree from "../sections/home4/ContentOne";
import { Link } from "gatsby";

const CareerDetails = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=311fcefd0ece4f7f86ec1672890481a4&metadata=01">
                <button className="btn btn btn-golden-yellow header-btn-10 rounded-10">
                Use In Snap
                </button>
              </a>
            </>
          ),
          footerStyle: "style4",
        }}
      >
        <main className="pt-23 pt-md-27 pb-13 pb-md-19 pt-lg-29 pb-lg-27">
          <ContentThree className="pb-13 pb-lg-34"/>
        </main>
      </PageWrapper>
    </>
  );
};
export default CareerDetails;
